import { useCallback, useEffect, useState } from "react";
import "../styles/calendar.scss";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { setDates } from "../../redux/action";
import { useDispatch } from "react-redux";
import CalenderPreset from '../enums/calendar-preset';
import {useTranslation} from "react-i18next";
import moment from "moment";
import {useTheme} from "../Providers/ThemeContext";

function Calendar({ selectedDate, endDate }) {
  const [days, setDays] = useState([]);
  const [toggleSelect, setToggleSelect] = useState(true);
  const [initialSelectedDate, setInitialSelectedDate] = useState(null);
  const {t} = useTranslation();
  const dayNames = [
    t("day_short_sunday"),
    t("day_short_monday"),
    t("day_short_tuesday"),
    t("day_short_wednesday"),
    t("day_short_thursday"),
    t("day_short_friday"),
    t("day_short_saturday"),
  ];
  const date = new Date();
  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth());
  const dispatch = useDispatch();
  const theme = useTheme();

  const [currentPreset, setCurrentPreset] = useState(CalenderPreset.None)

  useEffect(() => {
    setMonth(selectedDate.getMonth());
    setYear(selectedDate.getFullYear());
  }, [selectedDate]);

  const findDays = useCallback(() => {
    const monthDays = [];
    const startDate = new Date(year, month, 1);
    const startDay = startDate.getDay();
    startDate.setDate(startDate.getDate() - startDay);

    for (let i = 0; i < startDay; i++) {
      const isInRange = startDate >= selectedDate && startDate <= endDate;

      monthDays.push({
        day: startDate.getDate(),
        currentMonth: false,
        currentDate: false,
        inRange: isInRange,
      });

      startDate.setDate(startDate.getDate() + 1);
    }

    while (startDate.getMonth() === month) {
      const isInRange = startDate >= selectedDate && startDate <= endDate;

      monthDays.push({
        day: startDate.getDate(),
        currentMonth: startDate.getMonth() === month,
        currentDate:
          selectedDate &&
          selectedDate.getDate() === startDate.getDate() &&
          selectedDate.getMonth() === startDate.getMonth() &&
          selectedDate.getFullYear() === startDate.getFullYear(),
        inRange: isInRange,
      });

      startDate.setDate(startDate.getDate() + 1);
    }

    while (monthDays.length < 35) {
      const isInRange = startDate >= selectedDate && startDate <= endDate;

      monthDays.push({
        day: startDate.getDate(),
        currentMonth: false,
        currentDate: false,
        inRange: isInRange,
      });

      startDate.setDate(startDate.getDate() + 1);
    }

    return monthDays;
  }, [endDate, month, selectedDate, year]);

  const getCurrentDate = () => {
    return moment(`01-${month + 1}-${year}`, "DD-MM-YYYY").format("MMM YYYY")
  };

  const monthLeft = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const monthRight = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const setDate = (day, currentMonth) => {
    setCurrentPreset(CalenderPreset.None);
    if (currentMonth) {
      if (toggleSelect) {
        dispatch(setDates(new Date(year, month, day), null));
        setToggleSelect(false);
      } else {
        dispatch(setDates(selectedDate, new Date(year, month, day)));
        setToggleSelect(true);
      }
    }
  };

  useEffect(() => {
    if (!initialSelectedDate) {
      setInitialSelectedDate(selectedDate);
    }
    setDays(findDays());
  }, [month, initialSelectedDate, year, selectedDate, endDate, toggleSelect, findDays]);

  const presets = [
    {
      label: t('calendar_preset_day'),
      value: CalenderPreset.Day,
    },
    {
      label: t('calendar_preset_week'),
      value: CalenderPreset.Week,
    },
    {
      label: t('calendar_preset_month'),
      value: CalenderPreset.Month,
    },
    {
      label: t('calendar_preset_ytd'),
      value: CalenderPreset.Year,
    },
  ]

  const onPresetSelect = (preset) => {
    const currentDate = new Date();
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);
    if(preset === CalenderPreset.Day) {
      const endDate = new Date();
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      endDate.setMilliseconds(0);
      dispatch(setDates(currentDate, endDate));
    } else if(preset === CalenderPreset.Week) {
      const weekStartDate = new Date(currentDate);
      weekStartDate.setDate(weekStartDate.getDate() - weekStartDate.getDay())      
      const weekEndDate = new Date(weekStartDate);
      weekEndDate.setDate(weekEndDate.getDate() + 6);
      dispatch(setDates(weekStartDate, weekEndDate));
    } else if(preset === CalenderPreset.Month) {
      const monthStartDate = new Date(currentDate);
      monthStartDate.setDate(1);
      const monthEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      dispatch(setDates(monthStartDate, monthEndDate));
    } else if(preset === CalenderPreset.Year) {
      const startDate = new Date(currentDate.getFullYear(), 0, 1);
      dispatch(setDates(startDate, currentDate));
    }

    setCurrentPreset(preset);
  }

  return (
    <div className={`calendar pos-abs ${theme.getThemeClass()}`}>
      <div className="items-container">
        {
          presets.map((preset, index) => {
            return (
              <div className={`item ${preset.value === currentPreset ? 'active' : ''}`} key={index} onClick={() => onPresetSelect(preset.value)}>
                {preset.label}
              </div>
            )
          }) 
        }
      </div>
      <div className="calendar-container">
        <div className="date-display">{getCurrentDate()}</div>
        <div className="header-wrapper">
          <MdChevronLeft onClick={monthLeft} />
          <div className="header">
            {dayNames.map((day, index) => (
              <div key={index} className="item">
                {day}
              </div>
            ))}
          </div>
          <MdChevronRight onClick={monthRight} />
        </div>
        <div className="days">
          {days.map((day, index) => (
            <div
              key={index}
              className={`item ${day.currentMonth ? "" : "inactive"} ${
                day.currentDate ? "active" : ""
              } ${day.inRange ? "active" : ""}`}
              onClick={() => setDate(day.day, day.currentMonth)}
            >
              {day.day}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Calendar;
