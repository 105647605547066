import '../styles/outer.scss';
import LoginImage from '../images/LoginImage.png';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import {sendPost} from '../../common/request';
import links from '../../common/links';
import Loader from '../components/Loader';

function RegisterPage() {
    let navigation = useNavigate();
    let [email, setEmail] = useState('');
    let [username, setUsername] = useState('');
    let [password, setPassword] = useState('');
    let [confirmPassword, setConfirmPassword] = useState('');
    let [error, setError] = useState('');
    let [isLoading, setIsLoading] = useState(false);

    let register = async () => {
        setError('');
        let emailRegex = /^[a-zA-Z0-9.-_]*@[0-9A-Za-z]*\.[a-zA-Z0-9]*$/
        let fieldError = '';
        if(email === '' || !emailRegex.test(email)) {
            fieldError = 'Invalid Email';
        } else if (username === '') {
            fieldError = 'Invalid Username';
        } else if (password === '') {
            fieldError = 'Invalid Password';
        } else if (confirmPassword !== password) {
            fieldError = 'Passwords do not match';
        }

        if(fieldError !== '') {
            setError(fieldError);
            return;
        }
        setIsLoading(true);
        let response = await sendPost(links.Register, {
            email: email,
            username: username,
            password: password
        }, false);

        if(!response.error) {
            navigation('/login');
        } else {
            setError(response.error);
        }
        setIsLoading(false);
    }

    return (
        <div className="page-outer">
            <div className='body'>
                <div className='content'>
                    <div className='rect'></div>
                    <img src={LoginImage} alt='Login'/>
                </div>
                <div className='controls'>
                    <div className='title'>
                        Register
                    </div>
                    <div className='input-group'>
                        <label>Email Address</label>
                        <input type='email' value={email} placeholder='example@gmail.com' onChange={t => setEmail(t.target.value)} />
                    </div>
                    <div className='input-group'>
                        <label>Full Name</label>
                        <input type='text' placeholder='John Nolan' value={username} onChange={t => setUsername(t.target.value)} />
                    </div>
                    <div className='input-group'>
                        <label>Password</label>
                        <input type='password' placeholder='Enter your password' value={password} onChange={t => setPassword(t.target.value)}/>
                    </div>
                    <div className='input-group'>
                        <label>Confirm Password Password</label>
                        <input type='password' placeholder='Re-enter your password' value={confirmPassword} onChange={t => setConfirmPassword(t.target.value)}/>
                    </div>
                    {
                        error !== '' ?
                        <div className='input-group error'>
                            {error}
                        </div> :
                        <></>
                    }
                    <div className='input-group'>
                        <div className='btn btn-primary' onClick={register}>
                            {
                                isLoading ? <Loader color="white" height={20} barWidth={3}/> : 'Register'
                            }
                        </div>
                    </div>
                    <div className='input-group'>
                        <div className='register-link'>
                            <div className='note'>
                                Have an account?
                            </div>
                            <div className='note link bold' onClick={() => navigation('/login')}>
                                Login here.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegisterPage;