// store.js
import dateReducer from "./reducer";
import { combineReducers, createStore } from "redux";
import userReducer from "./userActions.js/reducer";
import accountReducer from "./selectedAccounts.js/reducer";
import headerReducer from './header/reducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import communityReducer from "./community/reducer";

const persistConfig = {
  key: 'root',
  storage,
};

const persistedDateReducer = persistReducer(persistConfig, dateReducer);

const persistedAccountsReducer = persistReducer(persistConfig, accountReducer);

const rootReducer = combineReducers({
  date: persistedDateReducer,
  user: userReducer,
  accounts: persistedAccountsReducer,
  header: headerReducer,
  community: communityReducer,
});

const store = createStore(rootReducer);
export default store;
const persistor = persistStore(store);
export { persistor };
