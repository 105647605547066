import React, {useEffect, useRef, useState} from "react";
import SideBar from "./assets/components/sidebar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import { setUser } from "./redux/userActions.js/action";
import "./common/i18n";
import i18next from "i18next";
import links, {getSocketLink} from "./common/links";
import moment from "moment";
import fr from 'moment/locale/fr';
import ar from 'moment/locale/ar';
import de from 'moment/locale/de';
import Header from "./assets/components/Header.jsx";
import { MdChevronLeft } from "react-icons/md";
import {sendGet} from "./common/request";
import {setUnReadMessages} from "./redux/community/action";
import {io} from "socket.io-client";
import {getToken} from "./common/token";
import SocketContext from "./assets/contexts/SocketContext";
import {useTheme} from "./assets/Providers/ThemeContext";

const Root = () => {
  const dispatch = useDispatch();
  const unReadMessages = useSelector(state => state.community.unReadMessages);
  let navigate = useNavigate();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const location = useLocation();
  const [socket, setSocket] = useState({});
  const socketRef = useRef();
  const unReadMessagesRef = useRef(0);
  const themeContext = useTheme();

  async function fetchUnReadMessagesCount() {
    const response = await sendGet(links.GetAllUnreadMessagesCount, true);
    console.log(response)
    if(response.error) {
      console.log(response.error);
    } else {
      dispatch(setUnReadMessages(response.unReadMessagesCount));
    }
  }

  async function fetchAccounts() {
    try {
      let token = localStorage.getItem("token");
      const response = await fetch(links.GetUser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        const data = await response.json();
        if(data.user.language === 'en') {
          moment.locale(data.user.language);
        } else if(data.user.language === 'de') {
          moment.locale(data.user.language, de);
        } else if(data.user.language === 'fr') {
          moment.locale(data.user.language, fr);
        } else if(data.user.language === 'ar') {
          moment.locale(data.user.language, ar);
        }

        themeContext.changeTheme(data.user.theme);

        i18next.changeLanguage(data.user.language);
        dispatch(
            setUser({
              user: data.user,
            })
        );
      } else if (response.status === 401) {
        navigate("/login");
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log("Error fetching accounts:", error);
    }
  }

  useEffect(() => {
    fetchAccounts();
    fetchUnReadMessagesCount();
  }, [dispatch, navigate]);

  useEffect(() => {
        setTimeout(() => {
          const sidebarExpandButtons = document.querySelectorAll(".sidebar-expand-btn");
          if(sidebarExpandButtons.length > 0) {
            for(let sidebarExpandBtn of sidebarExpandButtons) {
              sidebarExpandBtn.addEventListener("click", () => {
                  document.querySelector(".sidebar").classList.add("expanded");
              })
            }
          }
        }, 500)
    }, [location]);

  useEffect(() => {
    if(!socketRef.current) {
      const sLink = io(getSocketLink(), {
        auth: {
          token: getToken()
        },
      });
      socketRef.current = sLink;

      sLink.on('connect', () => {
        console.log('Socket Connection Successful');
        sLink.on('onChatMessageReceived', (data) => {
          unReadMessagesRef.current += 1;
          dispatch(setUnReadMessages(unReadMessagesRef.current));
        })
      })

      setSocket({socket: sLink});
    }
  }, []);

  useEffect(() => {
    unReadMessagesRef.current = unReadMessages;
  }, [unReadMessages]);

  return (
      <SocketContext.Provider value={socket}>
        <div className={`App ${isSidebarCollapsed ? 'collapsed' : ''} ${themeContext.getThemeClass()}`}>
          <div className={`sidebar`} id="sidebar">
            <div className="sidebar-toggle-btn" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
              <MdChevronLeft/>
            </div>
            <SideBar/>
          </div>
          <div className="page-container">
            <div className="page">
              <Header/>
              <Outlet/>
            </div>
          </div>
        </div>
      </SocketContext.Provider>
  );
};

export default Root;
