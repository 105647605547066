import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./assets/pages/dashboard";
import DailyJournal from "./assets/pages/dailyjournal";
import AddTrade from "./assets/pages/addtradepage";
import LoginPage from "./assets/pages/login";
import RegisterPage from "./assets/pages/register";
import ForgotPasswordPage from "./assets/pages/forgotpassword";
import TwoFactorAuthenticationPage from "./assets/pages/twofactorauthentication";
import ResetPasswordPage from "./assets/pages/resetpassword";
import JournalDetails from "./assets/pages/journalDetails";
import Settings from "./assets/pages/settings";
import ManageTagsPage from "./assets/pages/setting/ManageTagsPage";
import ProfilePage from "./assets/pages/setting/ProfilePage";
import AccountsPage from "./assets/pages/setting/AccountsPage";
import SecurityPage from "./assets/pages/setting/SecurityPage";
import Reports from "./assets/pages/Reports";
import Overview from "./assets/components/report/overview";
import Days from "./assets/components/report/days";
import Months from "./assets/components/report/months";
import Setups from "./assets/components/report/setups";
import TradePage from "./assets/pages/TradePage";
import NoteBook from "./assets/pages/NoteBook";
import Playbook from "./assets/pages/Playbook";
import PlaybookLayout from "./assets/pages/playbook-pages/playbook-layout";
import PlaybookOverviewPage from "./assets/pages/playbook-pages/playbook-overview";
import PlaybookRulesPage from "./assets/pages/playbook-pages/playbook-rules";
import PlaybookExecutions from "./assets/pages/playbook-pages/playbook-executions";
import ShareTradePage from "./assets/pages/share-trade-page";
import Community from "./assets/pages/community";
import EconomicCalendar from "./assets/pages/setting/EconomicCalendar";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
        meta: {
          name: "dashboard",
        },
      },
      {
        path: "/daily-journal",
        element: <DailyJournal />,
      },
      {
        path: "/daily-journal/:date",
        element: <JournalDetails />,
      },
      {
        path: "/trade/:id",
        element: <TradePage />,
      },
      {
        path: "/notebook",
        element: <NoteBook />,
      },
      {
        path: "/playbook",
        element: <Playbook />,
      },
      {
        path: "/playbook-details",
        element: <PlaybookLayout />,
        children: [
          {
            path: "overview/:id",
            element: <PlaybookOverviewPage />,
          },
          {
            path: "rules/:id",
            element: <PlaybookRulesPage />,
          },
          {
            path: "executions/:id",
            element: <PlaybookExecutions />,
          },
        ],
      },
      {
        path: "/community",
        element: <Community />,
      },
      {
        path: "/reports",
        element: <Reports />,
        children: [
          { path: "/reports/overview", element: <Overview /> },
          {
            path: "/reports/days",
            element: <Days />,
          },
          {
            path: "/reports/months",
            element: <Months />,
          },
          {
            path: "/reports/setups",
            element: <Setups />,
          },
        ],
      },
      {
        path: "/addtrade",
        element: <AddTrade />,
      },
      {
        path: "/settings",
        element: <Settings />,
        children: [
          {
            path: "manage/tags",
            element: <ManageTagsPage />,
          },
          {
            path: "profile",
            element: <ProfilePage />,
          },
          {
            path: "accounts",
            element: <AccountsPage />,
          },
          {
            path: "security",
            element: <SecurityPage />,
          },
          {
            path: "economic-calendar",
            element: <EconomicCalendar />,
          }
        ],
      },
    ],
  },
  {
    path: "/share/trade/:subAccountId/:tradeId",
    element: <ShareTradePage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/two-factor/:email",
    element: <TwoFactorAuthenticationPage />,
  },
  {
    path: "/reset-password/:email/:code",
    element: <ResetPasswordPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
