import { useCallback, useEffect, useRef, useState } from "react";
import Loader from "../../components/Loader";
import { useParams } from "react-router-dom";
import {sendGet} from '../../../common/request';
import Urls from '../../../common/links';
import currencyFormat from '../../../common/format';
import RuleShow from '../../utils/rule-show';
import { useTranslation } from "react-i18next";

function PlaybookRulesPage() {

    const [isLoading, setIsLoading] = useState(false);
    const {id} = useParams();
    const [playbook, setPlaybook] = useState({});
    const [criteriaGroups, setCriteriaGroups] = useState(undefined);
    const rulesData = useRef({});
    const {t} = useTranslation();

    const loadRules = useCallback(async () => {
        setIsLoading(true);
        const response = await sendGet(Urls.GetPlaybook(id), true);
        if(response.error) {
            console.log(response.error);
        } else {
            setPlaybook(response.playbook);
        }
        setIsLoading(false);
    }, [id]);

    useEffect(() => {
        loadRules();
    }, [loadRules]);

    useEffect(() => {

        if(playbook.criteriaGroups) {
            //Creating a group object
            const data = {};

            playbook.criteriaGroups.forEach(group => {
                data[group.id] = {};
                group.rules.forEach(rule => {
                    data[group.id][rule.id] = {
                        follows: 0,
                        total: 0,
                        netPnL: 0,
                        profitFactor: 'N/A',
                        winningTrades: 0,
                        totalProfit: 0,
                        totalLoss: 0,
                    }
                })
            })

            playbook.trades.forEach(trade => {

                let tradeType = RuleShow.Always;

                if(trade.data.PnL > 0) {
                    tradeType = RuleShow.TradeWinner;
                } else if(trade.data.PnL < 0) {
                    tradeType = RuleShow.TradeLoser;
                } else {
                    tradeType = RuleShow.TradeBreakEven;
                }

                trade.PlaybookTrade.criteriaGroups.forEach(group => {
                    group.rules.forEach(rule => {
                        
                        //If the rule is visible on the trade
                        if(rule.showRule === RuleShow.Always || (rule.showRule === tradeType)) {
                            data[group.id][rule.id].total += 1
                            if(rule.checked) {
                                data[group.id][rule.id].follows += 1
                            }
                            data[group.id][rule.id].netPnL += parseFloat(trade.data.PnL);
                            if(trade.data.PnL > 0) {
                                data[group.id][rule.id].winningTrades += 1;
                            }

                            if(trade.data.PnL >= 0) {
                                data[group.id][rule.id].totalProfit += parseFloat(trade.data.PnL)
                            } else {
                                data[group.id][rule.id].totalLoss += Math.abs(trade.data.PnL)
                            }
                        }

                    })
                })
            })

            rulesData.current = data;
            console.log(rulesData)
            setCriteriaGroups(playbook.criteriaGroups)
        }

    }, [playbook])

    return (
        <>
            {
                isLoading ? 
                <Loader fullScreen={true}/> : 
                <div className="inner-page-container rules-page">
                    {
                        criteriaGroups ? 
                        <>
                            <div className="header">
                                <div></div>
                                <div>{t("playbook_rules_column_follow_rate")}</div>
                                <div>{t("playbook_rules_column_net_profit_loss")}</div>
                                <div>{t("playbook_rules_column_profit_factor")}</div>
                                <div>{t("playbook_rules_column_win_rate")}</div>
                            </div>
                            {
                                criteriaGroups.map((group, gIndex) => {
                                    return (
                                        <div className="criteria-group" key={`group-${gIndex}`}>
                                            <div className="title">{group.title}</div>
                                            <div className="rules">
                                                {
                                                    group.rules.map((rule, rIndex) => {
                                                        const ruleData = rulesData.current[group.id][rule.id];
                                                        return (
                                                            <div className="rule" key={`group-${gIndex}rule-${rIndex}`}>
                                                                <div>{rule.value}</div>
                                                                <div>{((ruleData.follows / ruleData.total) * 100).toFixed(2)}%</div>
                                                                <div className={`${ruleData.netPnL >= 0 ? 'positive' : 'negative'}`}>{currencyFormat.format(ruleData.netPnL)}</div>
                                                                <div>{ruleData.totalLoss === 0 ? 'N/A' : (ruleData.totalProfit / ruleData.totalLoss).toFixed(2)}</div>
                                                                <div>{((ruleData.winningTrades / ruleData.total) * 100).toFixed(2)}%</div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </>
                        : <div className="empty-message">No rules found</div>
                    }
                </div>
            }
        </>
    )
}

export default PlaybookRulesPage;