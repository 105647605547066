import "../styles/outer.scss";
import LoginImage from "../images/LoginImage.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { sendPost } from "../../common/request";
import links from "../../common/links";
import { storeToken } from "../../common/token";
import Loader from "../components/Loader";

function LoginPage() {
  let navigation = useNavigate();
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [error, setError] = useState("");
  let emailRegex = /^[a-zA-Z0-9.-_]*@[0-9A-Za-z]*\.[a-zA-Z0-9]*$/;
  let [isLoading, setIsLoading] = useState(false);
  let login = async () => {
    setError("");
    let fieldError = "";
    if (email == "" || !emailRegex.test(email)) {
      fieldError = "Invalid Email";
    } else if (password == "") {
      fieldError = "Invalid Password";
    }

    if (fieldError != "") {
      setError(fieldError);
      return;
    }
    setIsLoading(true);
    let response = await sendPost(
      links.Login,
      {
        email: email,
        password: password,
      },
      false
    );
    if (!response.error) {
      storeToken(response.token);
      navigation("/");
    } else {
      setError(response.error);
    }
    setIsLoading(false);
  };

  return (
    <div className="page-outer">
      <div className="body">
        <div className="content">
          <div className="rect"></div>
          <img src={LoginImage} />
        </div>
        <div className="controls">
          <div className="title">Login</div>
          <div className="input-group">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="example@gmail.com"
              value={email}
              onChange={(t) => setEmail(t.target.value)}
            />
          </div>
          <div className="input-group">
            <label>Password</label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(t) => setPassword(t.target.value)}
            />
          </div>
          <div className="input-group">
            <div
              className="note right-aligned link"
              onClick={() => navigation("/forgot-password")}
            >
              Forgot Password?
            </div>
          </div>
          {error ? <div className="input-group error">{error}</div> : <></>}
          <div className="input-group">
            <div className="btn btn-primary" onClick={login}>
              {isLoading ? (
                <Loader color="white" height={20} barWidth={3} />
              ) : (
                "Login"
              )}
            </div>
          </div>
          <div className="input-group">
            <div className="register-link">
              <div className="note">Don't have an account?</div>
              <div
                className="note link bold"
                onClick={() => navigation("/register")}
              >
                Register
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
