import '../styles/sidebar.scss';
import {} from 'react-icons/md';


function SideBarButton({active, title, icon: Icon, href, click, type, count = 0}) {
    return (
        <div className='sidebar-btn-container'>
            {
                click ? 
                <div className={`sidebar-btn ${active ? 'active' : ''} ${type === 'danger' ? 'danger' : ''}`} onClick={click}>
                    <div className='btn-icon'>
                        <Icon/>
                    </div>
                    <div className='title'>
                        {title}
                    </div>
                </div> :
                <a className={`sidebar-btn ${active ? 'active' : ''} ${type === 'danger' ? 'danger' : ''}`} href={href}>
                    <div className="content">
                        <div className='btn-icon'>
                            <Icon/>
                        </div>
                        <div className='title'>
                            {title}
                        </div>
                    </div>
                    {
                        count > 0 ?
                            <div className="count">
                                {count}
                            </div> : <></>
                    }
                </a>
            }
            {
                active ?
                <div className='marker-container'>
                    <div className='active-marker'>
                    </div>
                </div>
                :
                <div></div>
            }
        </div>
    )
}

export default SideBarButton;