import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/reports.scss";
import { useTranslation } from "react-i18next";
import {useTheme} from "../Providers/ThemeContext";

const Report = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState({
    overview: true,
    days: false,
    months: false,
    setups: false,
  });
  let { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    // open overview page by default
    navigate("/reports/overview");
  }, []);

  return (
    <div className={`report-btn-container ${theme.getThemeClass()}`}>
      <div className="flex justify-between flex-wrap">
        <div className={`report-btn ${show.overview ? "active" : ""}`}
          onClick={() => {
            navigate("/reports/overview");
            setShow({
              overview: true,
              days: false,
              months: false,
              setups: false,
            });
          }}
        >
          {t("overview_report_page")}
        </div>
        <div
          className={`report-btn ${show.days ? "active" : ""} `}
          onClick={() => {
            navigate("/reports/days");
            setShow({
              overview: false,
              days: true,
              months: false,
              setups: false,
            });
          }}
        >
          {t("days_report_page")}
        </div>
        <div
          className={`report-btn ${show.months ? "active" : ""} `}
          onClick={() => {
            navigate("/reports/months");
            setShow({
              overview: false,
              days: false,
              months: true,
              setups: false,
            });
          }}
        >
          {t("months_report_page")}
        </div>
        <div
          className={`report-btn ${show.setups ? "active" : ""}  `}
          onClick={() => {
            navigate("/reports/setups");
            setShow({
              overview: false,
              days: false,
              months: false,
              setups: true,
            });
          }}
        >
          {t("setup_report_page")}
        </div>
      </div>
    </div>
  );
};

export default Report;
