import InfoToolTip from "../../components/InfoToolTip";
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Filler,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    RadialLinearScale,
    Tooltip,
    Title,
} from "chart.js";
import { useCallback, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import Loader from '../../components/Loader'
import {sendGet} from '../../../common/request';
import Urls from '../../../common/links';
import currencyFormat from '../../../common/format';
import moment from "moment";
import RuleShow from '../../utils/rule-show';
import { useTranslation } from "react-i18next";
import {useTheme} from "../../Providers/ThemeContext";

ChartJS.register(
    ArcElement,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    BarElement,
    Filler,
    RadialLinearScale,
    Tooltip
);

function PlaybookOverviewPage() {

    const {id} = useParams();
    const [trades, setTrades] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState({});
    const {t} = useTranslation();
    const [lineData, setLineData] = useState({
        labels: [],
        datasets: [
            {
                label: "Daily Cumulative Net P&L",
                data: [],
                borderColor: 'rgba(0, 255, 179, 0.5)',
                pointBackgroundColor: '#00ffb3',
                fill: {
                    target: 'origin',
                    above: 'rgba(41, 214, 168, 0.5)',
                    below: 'rgba(236, 120, 125, 0.5)'
                }
            },
        ],
    });
    const theme = useTheme();

    const loadPlaybookTrades = useCallback(async () => {
        setIsLoading(true);
        const response = await sendGet(Urls.GetPlaybook(id), true);
        if(response.error) {
            console.log(response.error);
        } else {
            console.log(response.playbook)
            setTrades(response.playbook.trades);
        }
        setIsLoading(false);
    }, [id])

    useEffect(() => {
        loadPlaybookTrades();
    }, [loadPlaybookTrades])

    const gridColor = theme.theme === 'dark' ? "#4A484F" : '#DEDDDF';
    let lineOptions = {
        responsive: true,
        tension: 0.4,
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
            },
          },
          y: {
              grid: {
                  color: gridColor,
                  tickColor: gridColor,
              },
              border: {
                  color: gridColor
              }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
        },
    };

    useEffect(() => {
        //Calculating the items
        if(trades.length > 0) {
            const parsedTrades = trades.map(t => {
                t.data.Date = moment(t.data.OpenTime).format('DD/MM/YYYY')
                t.data.PnL = parseFloat(t.data.PnL);
                t.data.OpenPrice = parseFloat(t.data.OpenPrice);
                return t;
            })
            .sort((t1, t2) => {
                const time1 = moment(t1.data.OpenTime).toDate().getTime();
                const time2 = moment(t2.data.OpenTime).toDate().getTime();
                return time1 - time2;
            })

            const netPnL = parsedTrades.map(t => t.data.PnL).reduce((a, b) => a + b);
            const totalTrades = parsedTrades.length;
            const winningTrades = parsedTrades.filter(t => t.data.PnL > 0);
            const losingTrades = parsedTrades.filter(t => t.data.PnL < 0);
            const winRate = ((winningTrades.length / totalTrades) * 100).toFixed(2);

            const totalProfit = winningTrades.length > 0 ? winningTrades.map(t => t.data.PnL).reduce((a, b) => a + b) : 0;
            const totalLoss = losingTrades.length > 0 ? losingTrades.map(t => t.data.PnL).reduce((a, b) => a + b) : 0;
            const profitFactor = totalLoss === 0 ? 'N/A' : (totalProfit / totalLoss).toFixed(2);
            const averageProfit = winningTrades.length > 0 ? totalProfit / winningTrades.length : 0;
            const averageLoss = losingTrades.length > 0 ? totalLoss / losingTrades.length : 0;

            const expectancy = (averageProfit * winRate) - (averageLoss * (1 - winRate));
            const maxProfit = winningTrades.length > 0 ? Math.max(...winningTrades.map(t => t.data.PnL)) : 0;
            const maxLoss = losingTrades.length > 0 ? Math.min(...losingTrades.map(t => t.data.PnL)) : 0;

            const winnerRMultiple = winningTrades.length > 0 ? winningTrades.map(t => {
                const openPrice = t.data.OpenPrice;
                const stopLoss = t.data.StopLoss;
                const pnl = t.data.PnL;

                return pnl / (openPrice - stopLoss);
            }).reduce((a, b) => a + b) : 0;

            const loserRMultiple = losingTrades.length > 0 ? losingTrades.map(t => {
                const openPrice = t.data.OpenPrice;
                const stopLoss = t.data.StopLoss;
                const pnl = t.data.PnL;

                return pnl / (openPrice - stopLoss);
            }).reduce((a, b) => a + b) : 0;

            const averageWinnerRMultiple = winningTrades.length > 0 ? (winnerRMultiple / winningTrades.length) : 0;
            const averageLoserRMultiple = losingTrades.length > 0 ? (loserRMultiple / losingTrades.length) : 0;
            const totalRMultiple = ((averageWinnerRMultiple + averageLoserRMultiple) / 2).toFixed(2);

            let totalApplicableRules = 0;
            let totalFollowedRules = 0;

            trades.forEach(trade => {
                const criteriaGroups = trade.PlaybookTrade.criteriaGroups;
                let tradeType = RuleShow.TradeWinner;
                const pnl = trade.data.PnL;

                if(pnl > 0) {
                    tradeType = RuleShow.TradeWinner;
                } else if(pnl < 0) {
                    tradeType = RuleShow.TradeLoser;
                } else {
                    tradeType = RuleShow.TradeBreakEven;
                }

                //Iterating over each criteria group to find rules
                criteriaGroups.forEach(group => {
                    //Iterating over each rule
                    group.rules.forEach(rule => {
                        if(rule.showRule === RuleShow.Always) {
                            totalApplicableRules += 1;
                            if(rule.checked) {
                                totalFollowedRules += 1;
                            }
                        }else {
                            if(rule.showRule === tradeType) {
                                totalApplicableRules += 1;
                                if(rule.checked) {
                                    totalFollowedRules += 1;
                                }   
                            }
                        }
                    })
                })
                
            });

            const rulesFollowed = totalApplicableRules == 0 ? 0 : ((totalFollowedRules / totalApplicableRules) * 100).toFixed(2);

            //Setting the data
            setData({
                netPnL: netPnL,
                trades: totalTrades,
                winRate: winRate,
                profitFactor: profitFactor,
                missedTrades: 0,
                expectancy: expectancy,
                averageProfit: averageProfit,
                averageLoss: averageLoss,
                largestProfit: maxProfit,
                largestLoss: maxLoss,
                winnerRMultiple: averageWinnerRMultiple.toFixed(2),
                loserRMultiple: averageLoserRMultiple.toFixed(2),
                totalRMultiple: totalRMultiple,
                rulesFollowed: rulesFollowed
            });

            //Calculating the chart data
            const dayData = {};

            parsedTrades.forEach(trade => {
                const date = trade.data.Date;
                if(date in dayData) {
                    dayData[date] += trade.data.PnL;
                } else {
                    dayData[date] = trade.data.PnL;
                }
            });

            const cumulativeDayData = {};
            let cumulativePnL = 0;
            Object.keys(dayData).forEach(date => {
                cumulativePnL += dayData[date];
                cumulativeDayData[date] = cumulativePnL;
            })

            setLineData({
                labels: Object.keys(cumulativeDayData),
                datasets: [
                  {
                    label: "Daily Cumulative Net P&L",
                    data: Object.values(cumulativeDayData),
                      borderColor: 'rgba(0, 255, 179, 0.5)',
                      pointBackgroundColor: '#00ffb3',
                      fill: {
                          target: 'origin',
                          above: 'rgba(41, 214, 168, 0.5)',
                          below: 'rgba(236, 120, 125, 0.5)'
                      }
                  },
                ],
            });
        }
    }, [trades])

    return (
        <>
            {
                isLoading ? 
                <Loader fullScreen={true}/> : 
                <div className="inner-page-container overview-page">
                    <div className="data-items">
                        <div className="data-items-row-6">
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_net_pnl")}
                                    <InfoToolTip message={t("playbook_overview_net_pnl_tooltip")} />
                                </div>
                                <div className={`value ${data.netPnL ? (data.netPnL >= 0 ? 'positive' : 'negative') : ''}`}>
                                    {
                                        data.netPnL !== undefined ? 
                                        currencyFormat.format(data.netPnL) : 
                                        '$0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_trades")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_trades_tooltip")} />
                                </div>
                                <div className="value">
                                    {data.trades ? data.trades : 0}
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_win_rate")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_win_rate_tooltip")} />
                                </div>
                                <div className="value">
                                    {data.winRate ? data.winRate : 0.00}%
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_profit_factor")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_profit_factor_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.profitFactor ? 
                                        data.profitFactor : '0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_missed_trades")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_missed_trades_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.missedTrades ? 
                                        data.missedTrades : '0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_expectancy")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_expectancy_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.expectancy ? 
                                        currencyFormat.format(data.expectancy) : 
                                        '$0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_rules_followed")}
                                    <InfoToolTip message={t("playbook_overview_rules_followed_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.rulesFollowed ? 
                                        data.rulesFollowed : 
                                        '0.00'
                                    }%
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_average_winner")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_average_winner_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.averageProfit ? 
                                        currencyFormat.format(data.averageProfit) : 
                                        '$0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_average_loser")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_average_loser_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.averageLoss ? 
                                        currencyFormat.format(data.averageLoss) : 
                                        '$0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_largest_profit")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_largest_profit_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.largestProfit ? 
                                        currencyFormat.format(data.largestProfit) : 
                                        '$0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_largest_loss")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_largest_loss_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.largestLoss ? 
                                        currencyFormat.format(data.largestLoss) :
                                       '$0.00' 
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_winner_multiple")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_winner_multiple_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.winnerRMultiple ? 
                                        data.winnerRMultiple : '0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_loser_multiple")}
                                    <InfoToolTip message={t("playbook_overview_loser_multiple_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.loserRMultiple ? 
                                        data.loserRMultiple : '0.00'
                                    }
                                </div>
                            </div>
                            <div className="data-item">
                                <div className="heading">
                                    {t("playbook_overview_total_multiple")}
                                    <InfoToolTip isLeft={true} message={t("playbook_overview_total_multiple_tooltip")} />
                                </div>
                                <div className="value">
                                    {
                                        data.totalRMultiple ? 
                                        data.totalRMultiple : '0.00'
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="chart-container">
                        <div className="heading">
                            {t("playbook_overview_daily_net_cumulative_pnl")}
                            <InfoToolTip isLeft={true} message={t("playbook_overview_daily_net_cumulative_pnl_tooltip")} />
                        </div>
                        <div className="chart">
                            <Line
                                data={lineData}
                                options={lineOptions}
                            />
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default PlaybookOverviewPage;